.help-block{
    color: #dd4b39 !important;
}

.has-error .input-group-addon {
    border-color: #dd4b39 !important;
}

.has-error .fa{
    color: #dd4b39 !important;
}

div.has-error>input,
div.has-error>select,
div.has-error>textarea
{
    color: #dd4b39 !important;
}

div.has-error>input::placeholder
{
    color: #dd4b39 !important;
}

.editor .has-error{
    border-color: #dd4b39 !important;
}

.radio label{
    margin-right: 10px;
}

.control-label{
    cursor: pointer;
}

/* CROP IMAGE STYLE */
.avatar-view {
    display: block;
    /*margin: 15% auto 5%;*/
    margin: auto;
    height: 220px;
    width: 220px;
    border: 3px solid #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0,0,0,.15);
    cursor: pointer;
    overflow: hidden;
}

.avatar-view img {
    width: 100%;
}

.avatar-body {
    padding-right: 15px;
    padding-left: 15px;
}

.avatar-upload {
    overflow: hidden;
}

.avatar-upload label {
    display: block;
    float: left;
    clear: left;
    width: 100px;
}

.avatar-upload input {
    display: block;
    margin-left: 110px;
}

.avatar-alert {
    margin-top: 10px;
    margin-bottom: 10px;
}

.avatar-wrapper {
    height: 364px;
    width: 100%;
    margin-top: 15px;
    box-shadow: inset 0 0 5px rgba(0,0,0,.25);
    background: url("../img/bg-perfil.jpg") no-repeat center;
    border: solid 2px #c7c7c7;
    border-radius: 4px;
    overflow: hidden;

}

.avatar-wrapper img {
    display: block;
    height: auto;
    max-width: 100%;
}

.avatar-preview {
    float: left;
    margin-top: 15px;
    margin-right: 15px;
    border: 1px solid #eee;
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;
}

.avatar-preview:hover {
    border-color: #ccf;
    box-shadow: 0 0 5px rgba(0,0,0,.15);
}

.avatar-preview img {
    width: 100%;
}

.preview-lg {
    height: 184px;
    width: 184px;
    margin-top: 15px;
}

.preview-md {
    height: 100px;
    width: 100px;
}

.preview-sm {
    height: 50px;
    width: 50px;
}

@media (min-width: 992px) {
    .avatar-preview {
        float: none;
    }
}

.avatar-btns {
    margin-top: 30px;
    margin-bottom: 15px;
}

.avatar-btns .btn-group {
    margin-right: 5px;
}

.loading {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff url("img/loading.gif") no-repeat center center;
    opacity: .75;
    filter: alpha(opacity=75);
    z-index: 20140628;
}

.l-points{
    margin-top: 10px;
}
/** CSS Report **/
.report{
    font-family: "Times New Roman" !important;
}
.report .footer { 
    position: fixed; 
    left: 0px;
    bottom: 20px;
    right: 0px;
    height: 200px;
}

.report h3{
    color: black;
}

.report .watermark {
    position: fixed;
    top: 70%;
    left: 50%;
    width: 100%;
    text-align: right;
    opacity: .6;
    /*transform: rotate(10deg);*/
    transform-origin: 50% 50%;
    z-index: -1000;
}

.report .cabecalho{
    margin-bottom:20px;
    margin-top: 10px;
}

.report .rodape{
    margin-top:0px;
}

