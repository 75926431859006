.img-galeria-foto{
    max-width: 100%;
    width: 395px;
    height: 160px;
    object-fit: cover;
    object-position: bottom right;
    margin-top: 10px;
    border: 1px solid #bfbebe;
    border-radius: 10px;
}

.btn-circulo{
    position: relative;
    z-index: 1;
    border-radius: 20px;
    margin-bottom: -30px;
    margin-top: 5px;
}

.a-a{
    margin-top: 30px;
    margin-right: -45px;
}

.label-transp{
    color: transparent;
}

.emlinha{
    display: inline;
}